<template>

</template>
<script>
    import {mapState} from 'vuex'
    export default {
        computed : {
          ...mapState(['user'])
        },
        mounted() {
            this.$router.push({'name' : 'dashboard'})
        }
    }
</script>
